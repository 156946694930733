<!--

Wishes

- Catch customer phone number and allow contact

- Tagesumsatz

- Filterfunktion nach Inhouse/Lieferdienst

-->

<template>

  <span v-for="station in stations" v-bind:key="station">
    <router-link :to="{name: 'Station', params: {station: station}}">{{ station }}</router-link> |
  </span>
  <h1>{{ formatDate(new Date()) }} </h1>
  <table v-if="orders.length">
    <tr>
      <th>
        BstNr 
      </th>
      <th>
        Preis
      </th>
      <th>
        Bestellzeit
        <br>
        Zubereitungszeit
        <br>
        Abholzeit
        abh
      </th>
      <th>
        Bearb.
      </th>
      <th>
        <table class="order-items-header">
          <tr>
            <th>
              Pos.
            </th>
            <th>
              Name
            </th>
            <th>
              Preis
            </th>
            <th>
              Code
            </th>
            <th>
              Station
            </th>
            <th>
              Optionen
            </th>
          </tr>
        </table>
      </th>
    </tr>
    <tr v-for="order in orders" v-bind:key="order.id" :class="statuses(order)">
      <td class="order-item-tags-parent">
        {{ order.id }}
        <div class="order-item-tags">
          <div v-if="order.wolt_order_id" class="order-item-tag order-item-wolt">
            Wolt
            {{ order.wolt_order_number }}
          </div>
          <div v-if="order.lieferando_order_id" class="order-item-tag order-item-lieferando">
            Lieferando
            {{ order.lieferando_public_reference }}
          </div>
          <div v-if="order.uber_order_id" class="order-item-tag order-item-uber">
            Uber
            {{ order.uber_order_display_id }}
          </div>
          <div v-if="order.name" class="order-item-tag order-item-name">
            {{ order.name }}
          </div>
          <div v-if="order.brand_name " class="order-item-tag order-item-brand">
            {{ order.brand_name }}
          </div>
          <div v-if="order.location == 'premises'" class="order-item-tag order-item-location">
            Vor Ort
          </div>
          <div v-if="order.location == 'takeaway'" class="order-item-tag order-item-location">
            Takeaway
          </div>
          <div v-if="order.location == 'delivery'" class="order-item-tag order-item-location">
            Lieferung
          </div>
          <div v-if="order.container == 'oneway'" class="order-item-tag order-item-location">
            Einweg
          </div>
          <div v-if="order.container == 'relevo'" class="order-item-tag order-item-location relevo">
            Relevo
            {{ order.relevo_id }}
          </div>
          <div v-if="order.container == 'vytal'" class="order-item-tag order-item-location vytal">
            Vytal
            {{ order.vytal_id }}
          </div>
          <div v-if="order.cutlery" class="order-item-tag order-item-cutlery">
            Besteck
          </div>
          <div v-if="order.comment" class="order-item-tag order-item-comment">
            {{ order.comment }}
          </div>
          <div v-if="order.percentage != null" style="padding-left: 10px" class="order-item-tag order-item-discount">
          {{ order.code }}: {{order.percentage}}%
          </div>
        </div>
      </td>
      <td class="price">
        <div v-if="order.percentage == null">
          <template v-if="order.reported_total && order.reported_total != order.total">
            <s>{{ as_eur(order.total) }}</s>
            {{ as_eur(order.reported_total) }}
          </template>
          <template v-else>
            {{ as_eur(order.total) }}
          </template>
        </div>
        <div v-if="order.percentage != null">
        <s>{{ as_eur(order.total_pre ) }}</s><br>
        {{ as_eur(order.total ) }}<br>
        </div>
      </td>
      <td class="times" v-if="order.preorder">
        <span class="time-receive"><img src="../assets/pencil.svg" />{{ formatTime(order.received) }}</span>
        <span class="time-cook" v-if="order.notify"><img src="../assets/cook.svg" />{{ formatTime(order.notify) }}</span>
        <span v-else>&nbsp;</span>
        <span class="time-pickup" v-if="order.scheduled"><img src="../assets/bag.svg" />{{ formatTime(order.scheduled) }}</span>
      </td>
      <td class="times" v-else>
        <span class="time-receive"><img src="../assets/pencil.svg" />{{ formatTime(order.received) }}</span>
        <span class="time-cook" v-if="order.confirmed"><img src="../assets/cook.svg" />{{ formatTime(order.confirmed) }}</span>
        <span v-else>&nbsp;</span>
        <span class="time-pickup" v-if="order.scheduled"><img src="../assets/bag.svg" />{{ formatTime(order.scheduled) }}</span>
      </td>
      <td v-if="order.cancelled" class="big-explainer cancelled">
        <div class="status">STORNO</div>
      </td>
      <td class="completed" v-else-if="order.completed">
        <!-- {{ formatTime(order.completed) }} -->
        <div class="status">FERTIG</div>
      </td>
      <td v-else-if="order.preorder && ( ! order.notified) " class="big-explainer preorder">
        <div class="status">VORBESTELLUNG</div>
        <div class="actions" v-if="! order.minutes">
          <button v-for="minutes in [5,10,15,20]" v-bind:key="minutes" @click="confirmed(order.id, minutes)">{{minutes}}</button>
        </div>
      </td>
      <td v-else-if="order.preorder && order.notified " class="big-explainer cooking">
        <div class="status">VORBESTELLUNG<br>KÜCHE</div>
        <button v-if="order.dismissed" class="action" @click="completed(order.id)">Fertig</button>
        <button v-if=" ! order.dismissed" class="action" @click="dismissed(order.id)">Bestätigen</button>
        <div class="driver" v-if="order.delivery_status != 'delivering' && order.delivery_status != 'delivered'">
          <img src="../assets/bicycle.svg">
          <span>{{ order.driver_name }}</span>
          <span v-if="order.delivery_status != 'arrived'">{{ formatGuess(delivery(order)) }}</span>
          <span v-else>Angekommen</span>
        </div>
        <div class="timer">
          <span>{{ formatTimer(elapsed(order)) }}</span>
        </div>
      </td>
      <td class="cooking" v-else-if="order.minutes">
        <!-- customer name should be there -->
        <div class="status">KÜCHE</div>
        <button class="action" @click="completed(order.id)">Fertig</button>
        <div class="driver" v-if="order.delivery_status != 'delivering' && order.delivery_status != 'delivered'">
          <img src="../assets/bicycle.svg">
          <span>{{ order.driver_name }}</span>
          <span v-if="order.delivery_status != 'arrived'">{{ formatGuess(delivery(order)) }}</span>
          <span v-else>Angekommen</span>
        </div>
        <div class="timer">
          <span>{{ formatTimer(elapsed(order)) }}</span>
        </div>
      </td>
      <td class="confirmed" v-else>
        <div class="status">NEU</div>
        <div class="actions">
          <button v-for="minutes in [5,10,15,20]" v-bind:key="minutes" @click="confirmed(order.id, minutes)">{{minutes}}</button>
        </div>
      </td>
      <td>
        <table class="order-items-body">
          <tr v-for="item in order.items" v-bind:key="item.key">
            <td>
              {{ item.index  }}
              von
              {{ order.items.length }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ as_eur(item.price) }}
              <!-- <small>(inkl. {{ as_percentage(item.tax) }}% MwSt.)</small> -->
            </td>
            <td>
              {{ item.code }}
            </td>
            <td>
              {{ item.station }}
            </td>
            <td>
              <div class="components" v-for="component in item.components" :key="component.id">
                {{ component.name }}
                <small>{{ as_eur(component.price) }} <!-- (inkl. {{ as_percentage(component.tax) }}% MWSt.) --></small>
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <div v-else>
    Es sind heute noch keine Bestellungen eingegangen
  </div>
</template>

<script>
export default {
  name: 'Orders',
  props: {
    msg: String
  },
  methods: {
    completed(order_id) {
      window.socket.send(JSON.stringify(["completed", order_id]))
    },
    confirmed(order_id, minutes) {
      window.socket.send(JSON.stringify(["confirmed", order_id, minutes]))
    },
    dismissed(order_id) {
      window.socket.send(JSON.stringify(["dismissed", order_id]))
    },
    notify(order_id, minutes) {
      window.socket.send(JSON.stringify(["notify", order_id, minutes]))
    },
    elapsed(order) {
      return order.scheduled - this.$store.state.serverTime
    },
    delivery(order) {
      return order.expected_time - this.$store.state.serverTime
    },
    statuses(order) {
      var st = []
      var e
      
      if (order.cancelled)
        st.push('cancelled')
      if (order.completed) {
        st.push('completed')
        e = order.scheduled - order.completed 
        if (e < 0)
          st.push('completed-late')
        else if (e < 120000)
          st.push('completed-close')
      } else if (order.preorder && ! order.notified)
        st.push('preorder')
      else if (order.confirmed) {
        st.push('cooking')
        e = this.elapsed(order)
        if (e < 0)
          st.push('cooking-late')
        else if (e < 120000)
          st.push('cooking-close')
      } else
        st.push('new')
      return st
    }
  },
  computed: {
    stations () {
      let dishes = this.$store.state.dishes
      let stations = []
      for (let i=0; i < dishes[0].stations.length; i++) {
        stations.push(dishes[0].stations[i][1])
      }
      return stations
    },
    orders () {
      // TODO: convert this to an iterator
      var orders = []
      for (var i = this.$store.state.orders.length - 1; i >= 0; i--) {
        //var total = 0
        var raw_order = this.$store.state.orders[i]
        var {items, ...order} = raw_order // shorthand, assign all except 'items' to order
        items = []
        var index = 0
        for (var raw_item of raw_order.items) {
          for (var j = 0; j < raw_item.quantity; j++) {
            index++
            var item = {}
            item.order_id = raw_order.id
            item.key = [raw_item.order_id, raw_item.code, raw_item.index].join("-")
            item.name = raw_item.name
            item.code = raw_item.code
            item.station = raw_item.station
            item.price = raw_item.price
            //total += item.price
            item.tax = raw_item.tax
            item.components = []
            item.index = index
            for (var component of raw_item.components) {
              if (component.index != j) continue
              item.components.push(component)
              // total += component.price
            }
            items.push(item)
          }
        }
        order.items = items
        orders.push(order)
      }
      return orders
    }
  }
}
</script>

<style lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
table {
  th {
    text-align: left;
  }
  td {
    text-align: left;
  }

  button {
    padding: 0 4px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    width: 160px;
    flex-wrap: wrap;
    button {
      font-weight: bold;
      font-size: 150%;
      height: 40px;
      width: 40px;
      text-align: center;
    }
  }

  button.action {
    font-weight: bold;
    font-size: 150%;
    text-align: center;
    padding: 10px 10px;
    margin: 0 auto;
    display: block;
  }

  .order-item-tags{
    margin: 3.6em -220px 0 0;
    padding-top: 1em;
    box-sizing: border-box;
  }
  .order-item-tag{
    margin: 2px;
    padding: 3px;
    box-sizing: border-box;
    display:inline-block;
    background: #fff;
    border: 1px #999 solid;
    color: #333;
    &.order-item-comment {
      background: #eef;
      border: 2px #00e solid;
      color: #00f;
    }
    &.order-item-wolt{
      background: #d3eaf2;
      border: 2px #2596be solid;
      color: #2596be;
    }
    &.order-item-uber{
      background: #e4f2d3;
      border: 2px #00b764 solid;
      color: #00b764;
    }
    &.order-item-lieferando{
      background: #fff1d5;
      border: 2px #fdb92e solid;
      color: #ce8a00;
    }
    &.order-item-location.relevo{
      background: #fdb92e;
      border: 2px #fdb92e solid;
      color: #fff;
      font-weight: bold;
    }
    &.order-item-location.vytal{
      background: #42b983;
      border: 2px #42b983 solid;
      color: #fff;
      font-weight: bold;
    }
    &.order-item-location.cancelled {
      background: #eff;
      border: 2px #fee solid;
      color: #fee;
      font-weight: bold;
    }
  }

  tr.cancelled {
    &:nth-child(even){
      background-color: #000;
    }
    &:nth-child(odd){
      background-color: #222;
    }
    color: white;
  }
  tr.preorder{
    &:nth-child(even){
      background-color: #ccf;
    }
    &:nth-child(odd){
      background-color: #aaf;
    }
  }


  tr {
    div.big-explainer {
      font-size: 200%;
      margin: 3px 0 -100px 0;
      font-weight: bold;
    }
    
    td.price {
      font-size: 150%;
    }
    td.times {
      font-size: 150%;
    
      span {
        display: block;
        margin-bottom: 3px;
        img {
          width: 17px;
          margin-right: 3px;
        }
      }
    }
  }

  tr.new {
    background-color: #efffdb;
  }

  div.status {
    font-size: 200%;
    text-align: center;
    padding: 10px;
    margin: 0 0 8px 0;
  }
  .completed div.status {
    background-color: #d7ffa5;
  }
  .completed div.status {
    background-color: #d7ffa5;
  }
  .new div.status {
    background-color: #d7ffa5;
  }
  .cooking div.status {
    background-color: #d7ffa5;
  }
  .preorder div.status {
    font-size: 137%;
    padding-left: 0;
    padding-right: 0;
  }
  .preorder div.status {
    background: none;
  }
  .cancelled div.status {
    background: none;
  }

  tr.cooking-close {
    background-color: #ffc;
    &:nth-child(even) {
      background-color: #ffa;
    }
    &:nth-child(odd) {
      background-color: #ffc;
    }
    .cooking div.status {
      background-color: #ffea00;
    }
  }

  tr.cooking-late {
    background-color: #fdd;
    &:nth-child(even) {
      background-color: #faa;
    }
    &:nth-child(odd) {
      background-color: #fbb;
    }
    .cooking div.status {
      background-color: #f77;
    }
  }
  tr.completed-close {
    .completed div.status {
      background-color: #ffea00;
    }
  }

  tr.completed-late {
    .completed div.status {
      background-color: #f77;
    }
  }

  div.driver {
    img {
      display: block;
      margin: 10px auto 1px auto;
      height: 40px;
    }
    span {
      text-align: center;
      font-size: 125%;
      font-weight: bold;
      display: block;
      margin: 0 0 8px 0;
    }
  }

  div.timer {
    span {
      display: block;
      font-weight: bold;
      font-size: 200%;
      text-align: center;
    }
  }

  .order-items-body {
    .components {
      small {
        display: block;
      }
    }
  }

}



</style>
